@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App{
  /* background-image: url("./images/headerback.png"); */
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(50, 54, 61);
  overflow-x: hidden;
}
.mebel-app{
  /* background-image: url("./images/headerback.png"); */
  /* background-attachment: fixed; */
  background: transparent !important;

}