.main-card{
  width: 100%;
  height: 100%;
  min-height: 210px;
  min-width: 200px;
  cursor: pointer;
  transition: all 1s;
}
.main-card:hover .arrow-image-left{
  animation: toLeft .8s linear;
}
.main-card:hover .arrow-image-right{
  animation: toRight .8s linear;
}

.main-card-ltr{
  /* border: 1px solid blue; */
  padding-left: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.main-card-ltr .main-card-header{
  width: 100%;
  max-width: 200px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 22px;
}
.main-card-ltr .main-card-footer{
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.main-card-footer span{
  display: inline-block;
  white-space: pre-wrap;
  width: 80px;
  line-height: 14px;
}
.main-card-ltr .main-card-footer svg{
  width: 80px;
  margin-left: 20px;
}



.main-card-img{
  position: absolute;
  top: 0;
  height: 170px;
  width: 240px;
  overflow: hidden;
}

.main-card-ltr .main-card-img{
  right: 0;
}
.main-card-img span{
  width: 100%;
  height: 100%;
}

.main-card-img img{
  height: 100%;
  width: 100%;
  object-fit: cover !important;
}

/* --- RTL ----- */
.main-card-rtl{
  padding-right: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  text-align: right;
}
.main-card-rtl .main-card-header{
  width: 100%;
  max-width: 200px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 22px;
}
.main-card-rtl .main-card-footer{
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.main-card-rtl .main-card-footer img{
  width: 40px;
  margin-right: 20px;
}
.main-card-rtl .main-card-footer svg{
  width: 80px;
  margin-right: 20px;
}

.main-card-rtl .main-card-img{
  left: 0;
}

.project-card-main{
  transition: all .4s ease;
}
.project-card{
  padding: 20px;
  transition: all .2s ease;
  cursor: pointer;
}
.project-card-main:nth-child(odd){
  background-color: #c9c9c9;
}

.project-card-main:nth-child(even){
  background-color: transparent;
}

.project-card .arrow-image-right path{
  transition: all .8s ease;
}
.project-card-header{
  text-align: center;
  overflow: hidden;
  border: 1px solid #707070;
  width: fit-content;
  margin: 0 auto;
  height: 240px;
  width: 100%;
}
.project-card-header span{
  height: 100%;
  width: 100%;
}
.project-card-header img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.project-card-main:hover{
  background-color: #e31e24;
  color: #fff;
}
.project-card:hover .arrow-image-left{
  animation: toLeft .8s linear;
}
.project-card:hover .arrow-image-right{
  animation: toRight .8s linear;
}
.project-card:hover .arrow-image-right path{
  fill: #fff;
}
.project-card-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 25px;
  padding-top: 25px;
}
.project-card-title{
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  width: 70%;
  margin-bottom: 15px;
}
.project-card-more{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-card-more span{
  display: inline-block;
  font-size: 12px;
  width: 90px;
  text-transform: uppercase;
}

.project-card-more svg{
  width: 80px;
  margin-left: 20px;
}

@keyframes toLeft{
  0% {
    transform: translateX(0);
  }
  20%{
    transform: translateX(-10px);
  }
  40%{
    transform: translateX(-20px);
  }
  60%{
    transform: translateX(-30px);
  }
  70%{
    transform: translateX(-20px);
  }
  80%{
    transform: translateX(-10px);
  }
  100%{
    transform: translateX(0);
  }

}

@keyframes toRight{
  0% {
    transform: translateX(0);
  }
  20%{
    transform: translateX(10px);
  }
  40%{
    transform: translateX(20px);
  }
  60%{
    transform: translateX(30px);
  }
  70%{
    transform: translateX(20px);
  }
  80%{
    transform: translateX(10px);
  }
  100%{
    transform: translateX(0);
  }

}

@media only screen and (max-width: 768px){
  .main-card-ltr, .main-card-rtl{
    padding: 10px;
  }
  .main-card-ltr .main-card-header,
  .main-card-rtl .main-card-header{
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 22px;
  }
  .main-card-img{
    position: static;
    height: auto;
    width: 100%;
  }
}