.header{
  height: 100vh;
}

.overlay{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  animation: heightmin 2.5s ease forwards;
  background: transparent !important;
}

.overlay-dark{
  width: 100%;
  height: 100%;
}

.hamburger-menu-div{
  position: absolute; 
  z-index: 10;
  top: 20px;
  left: 30px;
  cursor: pointer;
}

.header-center{
  color: #fff;
  text-align: center;
  min-height: 500px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header-logo svg{
  width: 500px;
  height: 200px;
}

.header-text{
  font-size: 32px;
  margin-top: 45px;
  line-height: 35px;
  font-weight: 400;
  height: 80px;
  overflow: hidden;
}

@keyframes heightmin {
  from{
    max-height: 100vh;
  }
  to{
    max-height: 600px;
  }
}

@media only screen and (max-width: 768px) {
  .header-center{
    color: #fff;
    text-align: center;
    min-height: 200px;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header-logo svg{
    width: 200px;
    height: auto;
  }
  .header-text{
    font-size: 22px;
    margin-top: 0;
  }

  
}