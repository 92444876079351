.mini-container{
  /* border: 1px solid black; */
  margin: 0 auto;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
}
.mini-container h2{
  font-weight: 700;
  width: 80%;
}
.mini-container p{
  line-height: 20px;
}

.page-title{
  font-weight: 800;
  width: 180px;
  text-transform: uppercase;
}


.category-img{
  border: 1px solid #707070;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  height: 250px;
  cursor: zoom-in;
}
.category-img span{
  height: 100%;
  width: 100%;
}
.category-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.main-about-div{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.about-div{
  /* margin-top: 20px; */
  /* background-color: #ec0007; */
  /* padding: 50px 70px; */
  color: #000;
  width: 100%;
  max-width: 850px;
  font-weight: 400;
  font-size: 20px;
  text-align: justify;
}
.about-images{
  /* border: 1px solid black; */
}
.about-image{
  width: 100%;
}
.about-image-1{
  width: 100%;
  max-width: 440px;
  display: inline-block;
  /* margin-bottom: 40px; */
}
.about-image-2{
  width: 100%;
  max-width: 440px;
  display: inline-block;
}
.about-image-3{
  width: 100%;
  max-width: 400px;
  display: inline-block;
}
.about-image span{
  width: 100%;
  height: 100%;

}
.about-image span img{
  width: 100%;
  height: 100%;
}

.project-desc{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.project-desc p{
  margin-top: 20px;
  line-height: 18px;
}

.main-project-img{
  border: 1px solid #707070;
  height: 100%;
  overflow: hidden;
}
.main-project-img span{
  width: 100%;
  height: 100%;
}
.main-project-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-div{
  padding: 0;
  margin: 0;
}
.map-div{
  min-height: 420px;
}
.form-div{
  padding: 40px;
  min-width: 450px;
}
.form-button{
  min-width: 160px;
}

.partners {
  padding-top: 2rem;
  /* background-color: rgb(57, 67, 77); */
  background-color: #fff;
  /* color: white; */
  color: black;
}

.partners-div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin-top: 1.5rem;
  justify-content: space-between;
}

.partner{
  /* border: 1px solid beige;
  padding: 6px; */
  /* width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; */
}

@media only screen and (max-width: 768px){
  .mini-container{
    padding: 15px;
  }
  .category-img{
    max-height: 150px;
    width: 100%;
  }
  .main-about-div{
    justify-content: flex-start;
  }
  .about-div{
    margin-top: 0;
    padding: 10px;
    font-size: 16px;
  }

  
  .main-project-img{
    border: 1px solid #707070;
    height: 100%;
    max-height: 220px;
  }

}