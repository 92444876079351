@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap);
.header{
  height: 100vh;
}

.overlay{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-animation: heightmin 2.5s ease forwards;
          animation: heightmin 2.5s ease forwards;
  background: transparent !important;
}

.overlay-dark{
  width: 100%;
  height: 100%;
}

.hamburger-menu-div{
  position: absolute; 
  z-index: 10;
  top: 20px;
  left: 30px;
  cursor: pointer;
}

.header-center{
  color: #fff;
  text-align: center;
  min-height: 500px;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header-logo svg{
  width: 500px;
  height: 200px;
}

.header-text{
  font-size: 32px;
  margin-top: 45px;
  line-height: 35px;
  font-weight: 400;
  height: 80px;
  overflow: hidden;
}

@-webkit-keyframes heightmin {
  from{
    max-height: 100vh;
  }
  to{
    max-height: 600px;
  }
}

@keyframes heightmin {
  from{
    max-height: 100vh;
  }
  to{
    max-height: 600px;
  }
}

@media only screen and (max-width: 768px) {
  .header-center{
    color: #fff;
    text-align: center;
    min-height: 200px;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header-logo svg{
    width: 200px;
    height: auto;
  }
  .header-text{
    font-size: 22px;
    margin-top: 0;
  }

  
}
.main-card{
  width: 100%;
  height: 100%;
  min-height: 210px;
  min-width: 200px;
  cursor: pointer;
  transition: all 1s;
}
.main-card:hover .arrow-image-left{
  -webkit-animation: toLeft .8s linear;
          animation: toLeft .8s linear;
}
.main-card:hover .arrow-image-right{
  -webkit-animation: toRight .8s linear;
          animation: toRight .8s linear;
}

.main-card-ltr{
  /* border: 1px solid blue; */
  padding-left: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.main-card-ltr .main-card-header{
  width: 100%;
  max-width: 200px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 22px;
}
.main-card-ltr .main-card-footer{
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.main-card-footer span{
  display: inline-block;
  white-space: pre-wrap;
  width: 80px;
  line-height: 14px;
}
.main-card-ltr .main-card-footer svg{
  width: 80px;
  margin-left: 20px;
}



.main-card-img{
  position: absolute;
  top: 0;
  height: 170px;
  width: 240px;
  overflow: hidden;
}

.main-card-ltr .main-card-img{
  right: 0;
}
.main-card-img span{
  width: 100%;
  height: 100%;
}

.main-card-img img{
  height: 100%;
  width: 100%;
  object-fit: cover !important;
}

/* --- RTL ----- */
.main-card-rtl{
  padding-right: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  text-align: right;
}
.main-card-rtl .main-card-header{
  width: 100%;
  max-width: 200px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 22px;
}
.main-card-rtl .main-card-footer{
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.main-card-rtl .main-card-footer img{
  width: 40px;
  margin-right: 20px;
}
.main-card-rtl .main-card-footer svg{
  width: 80px;
  margin-right: 20px;
}

.main-card-rtl .main-card-img{
  left: 0;
}

.project-card-main{
  transition: all .4s ease;
}
.project-card{
  padding: 20px;
  transition: all .2s ease;
  cursor: pointer;
}
.project-card-main:nth-child(odd){
  background-color: #c9c9c9;
}

.project-card-main:nth-child(even){
  background-color: transparent;
}

.project-card .arrow-image-right path{
  transition: all .8s ease;
}
.project-card-header{
  text-align: center;
  overflow: hidden;
  border: 1px solid #707070;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  height: 240px;
  width: 100%;
}
.project-card-header span{
  height: 100%;
  width: 100%;
}
.project-card-header img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.project-card-main:hover{
  background-color: #e31e24;
  color: #fff;
}
.project-card:hover .arrow-image-left{
  -webkit-animation: toLeft .8s linear;
          animation: toLeft .8s linear;
}
.project-card:hover .arrow-image-right{
  -webkit-animation: toRight .8s linear;
          animation: toRight .8s linear;
}
.project-card:hover .arrow-image-right path{
  fill: #fff;
}
.project-card-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 25px;
  padding-top: 25px;
}
.project-card-title{
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  width: 70%;
  margin-bottom: 15px;
}
.project-card-more{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-card-more span{
  display: inline-block;
  font-size: 12px;
  width: 90px;
  text-transform: uppercase;
}

.project-card-more svg{
  width: 80px;
  margin-left: 20px;
}

@-webkit-keyframes toLeft{
  0% {
    transform: translateX(0);
  }
  20%{
    transform: translateX(-10px);
  }
  40%{
    transform: translateX(-20px);
  }
  60%{
    transform: translateX(-30px);
  }
  70%{
    transform: translateX(-20px);
  }
  80%{
    transform: translateX(-10px);
  }
  100%{
    transform: translateX(0);
  }

}

@keyframes toLeft{
  0% {
    transform: translateX(0);
  }
  20%{
    transform: translateX(-10px);
  }
  40%{
    transform: translateX(-20px);
  }
  60%{
    transform: translateX(-30px);
  }
  70%{
    transform: translateX(-20px);
  }
  80%{
    transform: translateX(-10px);
  }
  100%{
    transform: translateX(0);
  }

}

@-webkit-keyframes toRight{
  0% {
    transform: translateX(0);
  }
  20%{
    transform: translateX(10px);
  }
  40%{
    transform: translateX(20px);
  }
  60%{
    transform: translateX(30px);
  }
  70%{
    transform: translateX(20px);
  }
  80%{
    transform: translateX(10px);
  }
  100%{
    transform: translateX(0);
  }

}

@keyframes toRight{
  0% {
    transform: translateX(0);
  }
  20%{
    transform: translateX(10px);
  }
  40%{
    transform: translateX(20px);
  }
  60%{
    transform: translateX(30px);
  }
  70%{
    transform: translateX(20px);
  }
  80%{
    transform: translateX(10px);
  }
  100%{
    transform: translateX(0);
  }

}

@media only screen and (max-width: 768px){
  .main-card-ltr, .main-card-rtl{
    padding: 10px;
  }
  .main-card-ltr .main-card-header,
  .main-card-rtl .main-card-header{
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 22px;
  }
  .main-card-img{
    position: static;
    height: auto;
    width: 100%;
  }
}
.mebel-footer{
  position: relative;
  background-color: #32363d;
  width: 100%;
  min-height: 250px;
  margin-top: -60px;
  z-index: 0;
  background: transparent !important;
}

.footer-inner{
  width: 100%;
  overflow: hidden;
  padding-top: 70px;
  color: #fff;
}
.footer-inner .footer-bottom{
  margin-top: 40px;
  font-size: 12px;
  font-weight: 300;
}
.footer-logo{
  text-align: right;
  margin-top: 100px;
  padding-left: 20px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.footer-logo svg{
  width: 75%;
  max-width: 200px;
  min-width: 180px;
}
.footer-nav-menu{
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.footer-nav-menu-item{
  margin-right: 10px;
}
.nav-divider{
  color: #fff;
  font-weight: 700;
  margin-right: 10px;
  cursor: default;
}
.footer-nav-menu-item a{
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.footer-info{
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  margin: 0 auto;
  width: 100%;
  max-width: 550px;
}

.footer-social{
  text-align: left;
  margin-top: 150px;
}
.footer-social a{
  margin-right: 20px;
  margin-left: 20px;
}
.insta-img{
  width: 25px;
  transition: all .2s ease;
}
.insta-img:hover{
  -webkit-filter: drop-shadow(0 0 2px #fff);
          filter: drop-shadow(0 0 2px #fff);
}
.fb-img{
  height: 30px;
  transition: all .2s ease;
}
.fb-img:hover{
  -webkit-filter: drop-shadow(0 0 2px #fff);
          filter: drop-shadow(0 0 2px #fff);
}

@media only screen and (max-width: 768px){
  .footer-logo{
    text-align: center;
    margin-top: 0;
  }
  .footer-nav-menu{
    flex-wrap: wrap;
    margin-top: 10px; 
  }
  .footer-logo svg{
    width: 120px;
  }
  .footer-info{
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    width: 100%;
    font-size: 10px;
  }
  .footer-social{
    margin-top: 10px;
    padding-left: 10px;
    width: 100%;
    text-align: center;
  }
  .insta-img{
    width: 25px;
  }
  .fb-img{
    height: 30px;
  }
  .fb-img:hover{
    -webkit-filter: drop-shadow(0 0 2px #fff);
            filter: drop-shadow(0 0 2px #fff);
  }
  .footer-inner .footer-bottom{
    margin-top: 10px;
    font-size: 9px;
    font-weight: 300;
  }
  
}
.navbar, .navbar > div{
  /* border: 1px solid white; */
}
.navbar-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100px;
  /* border: 1px solid white; */
}

.nav-menu{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 856px;
  margin-top: 20px;
}
.nav-menu-item{
  margin-right: 10px;
}
.nav-menu-item a{
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.nav-tel{
  color: #fff;
  position: absolute;
  top: 20px;
  right: 30px;
}
.nav-tel-inner{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  padding-right: 20px;
}
.nav-tel svg{
  height: 25px;
  margin-right: 10px;
}
.nav-tel-inner span{
  /* margin-bottom: 1px; */
}
.nav-tel path{
  fill: #fff;
}


/* LANGUAGE */
.nav-lang{
  position: absolute;
  right: 3rem;
  top: 3.6rem;
  color: white;
  display: flex;
  min-width: 60px;
  justify-content: space-between;
}
.nav-lang-inner {
  cursor: pointer;
  font-weight: 500;
}
.nav-lang-inner:hover {
  color: #E31E24;
}

/* Sidebar */
.sidebar-body-div{
  /* border: 1px solid white; */
  height: 98%;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  color: white;
  background-color: #ec0007;
}

.close-icon-sidebar{
  position: absolute;
  top: 20px;
  left: 30px;
  cursor: pointer;
}
.close-icon-sidebar svg{
  width: 30px;
  height: 30px;
}
.sidebar-list{
  margin-top: 60px;
  margin-left: 50px;
}
.sidebar-list ul{
  list-style: outside;
  font-size: 24px;
  line-height: 30px;
}
.sidebar-list ul li a,
.sidebar-list ul li span{
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-size: 18px;
  line-height: 15px;
  transition: all .5s ease;

}
.sidebar-list ul li a:hover{
  color: #000;
}
.menu-li{
  list-style: none;
}
.sidebar-menu-item .sidebar-menu-item-plus{
  margin-left: -25px;
  display: inline-block;
  margin-top: 15px !important;
  font-size: 25px !important;
  font-weight: 900;
  transition: all .5s ease;
  /* transform: rotate(45deg); */
  -webkit-animation: rerotate .5s;
          animation: rerotate .5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.sidebar-menu-item .active{
  -webkit-animation: rotate .5s;
          animation: rotate .5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.open-menu{
  overflow: hidden;
  -webkit-animation: openMenu .5s;
          animation: openMenu .5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.hide-menu{
  overflow: hidden;
  -webkit-animation: hideMenu .5s;
          animation: hideMenu .5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes openMenu{
  from {
    height: 0;
    opacity: 0;
  }
  to{
    opacity: 1;
    height: 140px;
  }
}
@keyframes openMenu{
  from {
    height: 0;
    opacity: 0;
  }
  to{
    opacity: 1;
    height: 140px;
  }
}
@-webkit-keyframes hideMenu{
  from {
    opacity: 1;
    height: 140px;
  }
  to{
    opacity: 0;
    height: 0;
  }
}
@keyframes hideMenu{
  from {
    opacity: 1;
    height: 140px;
  }
  to{
    opacity: 0;
    height: 0;
  }
}
@-webkit-keyframes rotate{
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(45deg);
  }
}
@keyframes rotate{
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(45deg);
  }
}
@-webkit-keyframes rerotate{
  from {
    transform: rotate(45deg);
  }
  to{
    transform: rotate(0);
  }
}
@keyframes rerotate{
  from {
    transform: rotate(45deg);
  }
  to{
    transform: rotate(0);
  }
}

.sidebar-footer ul{
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* border: 1px solid yellow; */
  padding-left: 0;
}
.sidebar-footer ul li {
  /* border: 1px solid white; */
  width: 30px;
  margin: 0 5px;
  cursor: pointer;
}
.sidebar-footer ul li:hover .tg{
  fill: #0099ff  
}
.sidebar-footer ul li:hover .fb{
  fill: #3b5998
}
.sidebar-footer ul li:hover .insta{
  fill: #fb3958 
}
.sidebar-copy{
  color: #fff;
  font-weight: 300;
  margin-left: 10px;
  font-size: .9em;
  text-align: center;
}
.sidebar-tel{
  text-align: center;
  position: relative;
  top: -10px;
  font-size: 32px;
}



.mebel-container{
  margin: 0 auto;
  width: 100%;
  min-width: 956px;
  max-width: 1100px;
  min-height: 400px;
  background-color: #dfdfdf;
  /* margin-top: -70px; */
  position: relative;
  z-index: 1;
  /* animation: toTop40px 1s ease forwards; */
}

@-webkit-keyframes toTop40px{
  from{
    margin-top: 0;
  }
  to{
    margin-top: -40px;
  }
}

@keyframes toTop40px{
  from{
    margin-top: 0;
  }
  to{
    margin-top: -40px;
  }
}

@media only screen and (max-width: 768px){
  .navbar-div{
    height: auto;
  }
  .nav-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 25px;
    width: 100%;
    margin-top: 70px;
  } 
  .nav-menu-item{
    margin-right: 25px;
  }

  .mebel-container{
    margin: 0 auto;
    width: 100%;
    min-width: 100%;
    min-height: 200px;
  }
  
}

.page-loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  touch-action: none;
  overflow: hidden;
  background-color: rgb(50,54,61);
}

#inTurnFadingTextG{
	width:auto;
  margin:auto;
  text-align: center;
  font-size: 80px;
}

.inTurnFadingTextG{
	color:white;
	font-family:Arial;
	text-decoration:none;
	font-weight:700;
  float:left;
  letter-spacing: 8px;
	animation-name:bounce_inTurnFadingTextG;
		-o-animation-name:bounce_inTurnFadingTextG;
		-ms-animation-name:bounce_inTurnFadingTextG;
		-webkit-animation-name:bounce_inTurnFadingTextG;
		-moz-animation-name:bounce_inTurnFadingTextG;
	animation-duration:2.09s;
		-o-animation-duration:2.09s;
		-ms-animation-duration:2.09s;
		-webkit-animation-duration:2.09s;
		-moz-animation-duration:2.09s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
}
.color-red{
  animation-name: bounce_inTurnFadingTextG_red;
    -o-animation-name:bounce_inTurnFadingTextG_red;
		-ms-animation-name:bounce_inTurnFadingTextG_red;
		-webkit-animation-name:bounce_inTurnFadingTextG_red;
		-moz-animation-name:bounce_inTurnFadingTextG_red;
}
#inTurnFadingTextG_1{
	animation-delay:0.5s;
		-o-animation-delay:0.5s;
		-ms-animation-delay:0.5s;
		-webkit-animation-delay:0.5s;
		-moz-animation-delay:0.5s;
}
#inTurnFadingTextG_2{
	animation-delay:0.75s;
		-o-animation-delay:0.75s;
		-ms-animation-delay:0.75s;
		-webkit-animation-delay:0.75s;
		-moz-animation-delay:0.75s;
}
#inTurnFadingTextG_3{
	animation-delay:1.05s;
		-o-animation-delay:1.05s;
		-ms-animation-delay:1.05s;
		-webkit-animation-delay:1.05s;
		-moz-animation-delay:1.05s;
}
#inTurnFadingTextG_4{
	animation-delay:.2s;
		-o-animation-delay:.2s;
		-ms-animation-delay:.2s;
		-webkit-animation-delay:.2s;
		-moz-animation-delay:.2s;
}
#inTurnFadingTextG_5{
	animation-delay:.6s;
		-o-animation-delay:.6s;
		-ms-animation-delay:.6s;
		-webkit-animation-delay:.6s;
		-moz-animation-delay:.6s;
}
#inTurnFadingTextG_6{
	animation-delay:1s;
		-o-animation-delay:1s;
		-ms-animation-delay:1s;
		-webkit-animation-delay:1s;
		-moz-animation-delay:1s;
}
#inTurnFadingTextG_7{
	animation-delay:1.4s;
		-o-animation-delay:1.4s;
		-ms-animation-delay:1.4s;
		-webkit-animation-delay:1.4s;
		-moz-animation-delay:1.4s;
}
#inTurnFadingTextG_8{
	animation-delay:1.8s;
		-o-animation-delay:1.8s;
		-ms-animation-delay:1.8s;
		-webkit-animation-delay:1.8s;
		-moz-animation-delay:1.8s;
}

@keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(255,255,255);
	}

	100%{
		color:rgb(50,54,61);
	}
}

@-webkit-keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(255,255,255);
	}

	100%{
		color:rgb(50,54,61);
	}
}
/* ------------------------------------- */

@keyframes bounce_inTurnFadingTextG_red{
	0%{
		color:rgb(227,30,36);
	}

	100%{
		color:rgb(50,54,61);
	}
}

@-webkit-keyframes bounce_inTurnFadingTextG_red{
	0%{
		color:rgb(227,30,36);
	}

	100%{
		color:rgb(50,54,61);
	}
}
@media only screen and (max-width: 956px){
  .sidebar-body-div{
      width: 90vw !important;
  }
  
  #inTurnFadingTextG{
    width:auto;
    margin:auto;
    text-align: center;
    font-size: 50px;
  }
}


.mini-container{
  /* border: 1px solid black; */
  margin: 0 auto;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;
}
.mini-container h2{
  font-weight: 700;
  width: 80%;
}
.mini-container p{
  line-height: 20px;
}

.page-title{
  font-weight: 800;
  width: 180px;
  text-transform: uppercase;
}


.category-img{
  border: 1px solid #707070;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  height: 250px;
  cursor: zoom-in;
}
.category-img span{
  height: 100%;
  width: 100%;
}
.category-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.main-about-div{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.about-div{
  /* margin-top: 20px; */
  /* background-color: #ec0007; */
  /* padding: 50px 70px; */
  color: #000;
  width: 100%;
  max-width: 850px;
  font-weight: 400;
  font-size: 20px;
  text-align: justify;
}
.about-images{
  /* border: 1px solid black; */
}
.about-image{
  width: 100%;
}
.about-image-1{
  width: 100%;
  max-width: 440px;
  display: inline-block;
  /* margin-bottom: 40px; */
}
.about-image-2{
  width: 100%;
  max-width: 440px;
  display: inline-block;
}
.about-image-3{
  width: 100%;
  max-width: 400px;
  display: inline-block;
}
.about-image span{
  width: 100%;
  height: 100%;

}
.about-image span img{
  width: 100%;
  height: 100%;
}

.project-desc{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.project-desc p{
  margin-top: 20px;
  line-height: 18px;
}

.main-project-img{
  border: 1px solid #707070;
  height: 100%;
  overflow: hidden;
}
.main-project-img span{
  width: 100%;
  height: 100%;
}
.main-project-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-div{
  padding: 0;
  margin: 0;
}
.map-div{
  min-height: 420px;
}
.form-div{
  padding: 40px;
  min-width: 450px;
}
.form-button{
  min-width: 160px;
}

.partners {
  padding-top: 2rem;
  /* background-color: rgb(57, 67, 77); */
  background-color: #fff;
  /* color: white; */
  color: black;
}

.partners-div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 1.5rem;
  justify-content: space-between;
}

.partner{
  /* border: 1px solid beige;
  padding: 6px; */
  /* width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; */
}

@media only screen and (max-width: 768px){
  .mini-container{
    padding: 15px;
  }
  .category-img{
    max-height: 150px;
    width: 100%;
  }
  .main-about-div{
    justify-content: flex-start;
  }
  .about-div{
    margin-top: 0;
    padding: 10px;
    font-size: 16px;
  }

  
  .main-project-img{
    border: 1px solid #707070;
    height: 100%;
    max-height: 220px;
  }

}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App{
  /* background-image: url("./images/headerback.png"); */
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(50, 54, 61);
  overflow-x: hidden;
}
.mebel-app{
  /* background-image: url("./images/headerback.png"); */
  /* background-attachment: fixed; */
  background: transparent !important;

}
