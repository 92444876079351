.navbar, .navbar > div{
  /* border: 1px solid white; */
}
.navbar-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100px;
  /* border: 1px solid white; */
}

.nav-menu{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 856px;
  margin-top: 20px;
}
.nav-menu-item{
  margin-right: 10px;
}
.nav-menu-item a{
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.nav-tel{
  color: #fff;
  position: absolute;
  top: 20px;
  right: 30px;
}
.nav-tel-inner{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  padding-right: 20px;
}
.nav-tel svg{
  height: 25px;
  margin-right: 10px;
}
.nav-tel-inner span{
  /* margin-bottom: 1px; */
}
.nav-tel path{
  fill: #fff;
}


/* LANGUAGE */
.nav-lang{
  position: absolute;
  right: 3rem;
  top: 3.6rem;
  color: white;
  display: flex;
  min-width: 60px;
  justify-content: space-between;
}
.nav-lang-inner {
  cursor: pointer;
  font-weight: 500;
}
.nav-lang-inner:hover {
  color: #E31E24;
}

/* Sidebar */
.sidebar-body-div{
  /* border: 1px solid white; */
  height: 98%;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  color: white;
  background-color: #ec0007;
}

.close-icon-sidebar{
  position: absolute;
  top: 20px;
  left: 30px;
  cursor: pointer;
}
.close-icon-sidebar svg{
  width: 30px;
  height: 30px;
}
.sidebar-list{
  margin-top: 60px;
  margin-left: 50px;
}
.sidebar-list ul{
  list-style: outside;
  font-size: 24px;
  line-height: 30px;
}
.sidebar-list ul li a,
.sidebar-list ul li span{
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-size: 18px;
  line-height: 15px;
  transition: all .5s ease;

}
.sidebar-list ul li a:hover{
  color: #000;
}
.menu-li{
  list-style: none;
}
.sidebar-menu-item .sidebar-menu-item-plus{
  margin-left: -25px;
  display: inline-block;
  margin-top: 15px !important;
  font-size: 25px !important;
  font-weight: 900;
  transition: all .5s ease;
  /* transform: rotate(45deg); */
  animation: rerotate .5s;
  animation-fill-mode: forwards;
}
.sidebar-menu-item .active{
  animation: rotate .5s;
  animation-fill-mode: forwards;
}
.open-menu{
  overflow: hidden;
  animation: openMenu .5s;
  animation-fill-mode: forwards;
}
.hide-menu{
  overflow: hidden;
  animation: hideMenu .5s;
  animation-fill-mode: forwards;
}
@keyframes openMenu{
  from {
    height: 0;
    opacity: 0;
  }
  to{
    opacity: 1;
    height: 140px;
  }
}
@keyframes hideMenu{
  from {
    opacity: 1;
    height: 140px;
  }
  to{
    opacity: 0;
    height: 0;
  }
}
@keyframes rotate{
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(45deg);
  }
}
@keyframes rerotate{
  from {
    transform: rotate(45deg);
  }
  to{
    transform: rotate(0);
  }
}

.sidebar-footer ul{
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* border: 1px solid yellow; */
  padding-left: 0;
}
.sidebar-footer ul li {
  /* border: 1px solid white; */
  width: 30px;
  margin: 0 5px;
  cursor: pointer;
}
.sidebar-footer ul li:hover .tg{
  fill: #0099ff  
}
.sidebar-footer ul li:hover .fb{
  fill: #3b5998
}
.sidebar-footer ul li:hover .insta{
  fill: #fb3958 
}
.sidebar-copy{
  color: #fff;
  font-weight: 300;
  margin-left: 10px;
  font-size: .9em;
  text-align: center;
}
.sidebar-tel{
  text-align: center;
  position: relative;
  top: -10px;
  font-size: 32px;
}



.mebel-container{
  margin: 0 auto;
  width: 100%;
  min-width: 956px;
  max-width: 1100px;
  min-height: 400px;
  background-color: #dfdfdf;
  /* margin-top: -70px; */
  position: relative;
  z-index: 1;
  /* animation: toTop40px 1s ease forwards; */
}

@keyframes toTop40px{
  from{
    margin-top: 0;
  }
  to{
    margin-top: -40px;
  }
}

@media only screen and (max-width: 768px){
  .navbar-div{
    height: auto;
  }
  .nav-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 25px;
    width: 100%;
    margin-top: 70px;
  } 
  .nav-menu-item{
    margin-right: 25px;
  }

  .mebel-container{
    margin: 0 auto;
    width: 100%;
    min-width: 100%;
    min-height: 200px;
  }
  
}

.page-loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  touch-action: none;
  overflow: hidden;
  background-color: rgb(50,54,61);
}

#inTurnFadingTextG{
	width:auto;
  margin:auto;
  text-align: center;
  font-size: 80px;
}

.inTurnFadingTextG{
	color:white;
	font-family:Arial;
	text-decoration:none;
	font-weight:700;
  float:left;
  letter-spacing: 8px;
	animation-name:bounce_inTurnFadingTextG;
		-o-animation-name:bounce_inTurnFadingTextG;
		-ms-animation-name:bounce_inTurnFadingTextG;
		-webkit-animation-name:bounce_inTurnFadingTextG;
		-moz-animation-name:bounce_inTurnFadingTextG;
	animation-duration:2.09s;
		-o-animation-duration:2.09s;
		-ms-animation-duration:2.09s;
		-webkit-animation-duration:2.09s;
		-moz-animation-duration:2.09s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
}
.color-red{
  animation-name: bounce_inTurnFadingTextG_red;
    -o-animation-name:bounce_inTurnFadingTextG_red;
		-ms-animation-name:bounce_inTurnFadingTextG_red;
		-webkit-animation-name:bounce_inTurnFadingTextG_red;
		-moz-animation-name:bounce_inTurnFadingTextG_red;
}
#inTurnFadingTextG_1{
	animation-delay:0.5s;
		-o-animation-delay:0.5s;
		-ms-animation-delay:0.5s;
		-webkit-animation-delay:0.5s;
		-moz-animation-delay:0.5s;
}
#inTurnFadingTextG_2{
	animation-delay:0.75s;
		-o-animation-delay:0.75s;
		-ms-animation-delay:0.75s;
		-webkit-animation-delay:0.75s;
		-moz-animation-delay:0.75s;
}
#inTurnFadingTextG_3{
	animation-delay:1.05s;
		-o-animation-delay:1.05s;
		-ms-animation-delay:1.05s;
		-webkit-animation-delay:1.05s;
		-moz-animation-delay:1.05s;
}
#inTurnFadingTextG_4{
	animation-delay:.2s;
		-o-animation-delay:.2s;
		-ms-animation-delay:.2s;
		-webkit-animation-delay:.2s;
		-moz-animation-delay:.2s;
}
#inTurnFadingTextG_5{
	animation-delay:.6s;
		-o-animation-delay:.6s;
		-ms-animation-delay:.6s;
		-webkit-animation-delay:.6s;
		-moz-animation-delay:.6s;
}
#inTurnFadingTextG_6{
	animation-delay:1s;
		-o-animation-delay:1s;
		-ms-animation-delay:1s;
		-webkit-animation-delay:1s;
		-moz-animation-delay:1s;
}
#inTurnFadingTextG_7{
	animation-delay:1.4s;
		-o-animation-delay:1.4s;
		-ms-animation-delay:1.4s;
		-webkit-animation-delay:1.4s;
		-moz-animation-delay:1.4s;
}
#inTurnFadingTextG_8{
	animation-delay:1.8s;
		-o-animation-delay:1.8s;
		-ms-animation-delay:1.8s;
		-webkit-animation-delay:1.8s;
		-moz-animation-delay:1.8s;
}

@keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(255,255,255);
	}

	100%{
		color:rgb(50,54,61);
	}
}

@-o-keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(255,255,255);
	}

	100%{
		color:rgb(50,54,61);
	}
}

@-ms-keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(255,255,255);
	}

	100%{
		color:rgb(50,54,61);
	}
}

@-webkit-keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(255,255,255);
	}

	100%{
		color:rgb(50,54,61);
	}
}

@-moz-keyframes bounce_inTurnFadingTextG{
	0%{
		color:rgb(255,255,255);
	}

	100%{
		color:rgb(50,54,61);
	}
}
/* ------------------------------------- */

@keyframes bounce_inTurnFadingTextG_red{
	0%{
		color:rgb(227,30,36);
	}

	100%{
		color:rgb(50,54,61);
	}
}

@-o-keyframes bounce_inTurnFadingTextG_red{
	0%{
		color:rgb(227,30,36);
	}

	100%{
		color:rgb(50,54,61);
	}
}

@-ms-keyframes bounce_inTurnFadingTextG_red{
	0%{
		color:rgb(227,30,36);
	}

	100%{
		color:rgb(50,54,61);
	}
}

@-webkit-keyframes bounce_inTurnFadingTextG_red{
	0%{
		color:rgb(227,30,36);
	}

	100%{
		color:rgb(50,54,61);
	}
}

@-moz-keyframes bounce_inTurnFadingTextG_red{
	0%{
		color:rgb(227,30,36);
	}

	100%{
		color:rgb(50,54,61);
	}
}
@media only screen and (max-width: 956px){
  .sidebar-body-div{
      width: 90vw !important;
  }
  
  #inTurnFadingTextG{
    width:auto;
    margin:auto;
    text-align: center;
    font-size: 50px;
  }
}

