.mebel-footer{
  position: relative;
  background-color: #32363d;
  width: 100%;
  min-height: 250px;
  margin-top: -60px;
  z-index: 0;
  background: transparent !important;
}

.footer-inner{
  width: 100%;
  overflow: hidden;
  padding-top: 70px;
  color: #fff;
}
.footer-inner .footer-bottom{
  margin-top: 40px;
  font-size: 12px;
  font-weight: 300;
}
.footer-logo{
  text-align: right;
  margin-top: 100px;
  padding-left: 20px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.footer-logo svg{
  width: 75%;
  max-width: 200px;
  min-width: 180px;
}
.footer-nav-menu{
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.footer-nav-menu-item{
  margin-right: 10px;
}
.nav-divider{
  color: #fff;
  font-weight: 700;
  margin-right: 10px;
  cursor: default;
}
.footer-nav-menu-item a{
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.footer-info{
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  margin: 0 auto;
  width: 100%;
  max-width: 550px;
}

.footer-social{
  text-align: left;
  margin-top: 150px;
}
.footer-social a{
  margin-right: 20px;
  margin-left: 20px;
}
.insta-img{
  width: 25px;
  transition: all .2s ease;
}
.insta-img:hover{
  filter: drop-shadow(0 0 2px #fff);
}
.fb-img{
  height: 30px;
  transition: all .2s ease;
}
.fb-img:hover{
  filter: drop-shadow(0 0 2px #fff);
}

@media only screen and (max-width: 768px){
  .footer-logo{
    text-align: center;
    margin-top: 0;
  }
  .footer-nav-menu{
    flex-wrap: wrap;
    margin-top: 10px; 
  }
  .footer-logo svg{
    width: 120px;
  }
  .footer-info{
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    width: 100%;
    font-size: 10px;
  }
  .footer-social{
    margin-top: 10px;
    padding-left: 10px;
    width: 100%;
    text-align: center;
  }
  .insta-img{
    width: 25px;
  }
  .fb-img{
    height: 30px;
  }
  .fb-img:hover{
    filter: drop-shadow(0 0 2px #fff);
  }
  .footer-inner .footer-bottom{
    margin-top: 10px;
    font-size: 9px;
    font-weight: 300;
  }
  
}